import {
  EmailSettings,
  EmailSettingsTemplate,
} from '@myneva-portals/projects/src/components';
import {
  RestUsersService,
  ITemplateDetails,
  ITemplateRequestDetails,
} from '@myneva-portals/services/src/services';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigationSelector } from '../../state/reducers/navigation';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import { defaultErrorToast } from '../../components/sections/static-data/default-error-toast';
import { showToast } from 'front-components/src/components';
import { useQuery, useMutation } from 'react-query';
import { useDropzone } from 'react-dropzone';

export const EmailsSettingsView: React.FC = () => {
  const { t } = useTranslation();
  const toastConfig = defaultErrorToast();
  const url = process.env.WEB_GATEWAY_URL + '/core/settings/';
  const userService = new RestUsersService(url);
  const { breadcrumbs } = useSelector(navigationSelector);

  const [isGeneralView, setIsGeneralView] = React.useState(true);
  const customBreadCrumbConfig = [
    {
      breadcrumbName: t('APPEARANCE_SETTINGS'),
      path: '/appearance-settings',
    },
  ];

  const emailTemplate = useQuery(
    'getEmailTemplatesQuery',
    () =>
      userService.getEmailTemplates().then((data: ITemplateDetails[]) => data),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        showToast(toastConfig);
      },
    }
  );

  const { mutate: addOrEditEmailTemplate } = useMutation(
    (template: ITemplateRequestDetails) =>
      userService.addOrEditEmailTemplate(template).then(() => {
        emailTemplate.refetch();
      }),
    {
      onError: () => {
        showToast(toastConfig);
      },
    }
  );

  return (
    <EmailSettings
      translation={t}
      breadcrumbs={breadcrumbs}
      emailTemplateData={emailTemplate}
      addOrEditEmailTemplate={addOrEditEmailTemplate}
    />
  );
};
